import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Prerequisites`}</h3>
    <p>{`You will need:`}</p>
    <ul>
      <li parentName="ul">{`An account on the smallstep platform`}<br parentName="li"></br>
        {`Need one? `}<a parentName="li" {...{
          "href": "https://smallstep.com/signup?product=ssh"
        }}>{`Register here`}</a></li>
      <li parentName="ul">{`Okta Super Administrator privileges`}</li>
      <li parentName="ul">{`Okta Lifecycle Management Subscription`}</li>
      <li parentName="ul">{`Two (or more) Okta groups:`}
        <ul parentName="li">
          <li parentName="ul">{`A group whose members should be allowed to SSH (we'll refer to this as the `}<inlineCode parentName="li">{`ssh`}</inlineCode>{` group, but you can call it whatever you want)`}</li>
          <li parentName="ul">{`A group whose members should be allowed to `}<inlineCode parentName="li">{`sudo`}</inlineCode>{` (we'll refer to this as the `}<inlineCode parentName="li">{`sudo`}</inlineCode>{` group, but you can call it whatever you want)`}</li>
          <li parentName="ul">{`You can use existing groups or create new ones (Directory → Groups → Add Group)`}</li>
          <li parentName="ul">{`Make sure you add users that should be allowed to ssh/sudo to the respective groups (Directory → Groups → select group → Manage People)`}</li>
          <li parentName="ul">{`For more granular access, you can create multiple groups and grant access to a subset of smallstep managed hosts.`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`Features`}</h3>
    <p>{`The following provisioning features are supported:`}</p>
    <ul>
      <li parentName="ul">{`Push Groups and New Users`}
        <ul parentName="li">
          <li parentName="ul">{`New users created through OKTA will also be created in the third party application.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Push Profile or Group Updates`}
        <ul parentName="li">
          <li parentName="ul">{`Updates made to the user's profile through OKTA will be pushed to the third party application.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Push User Deactivation`}
        <ul parentName="li">
          <li parentName="ul">{`Deactivating the user or disabling the user's access to the application through OKTA will deactivate the user in the third party application.`}</li>
          <li parentName="ul">{`Note: For this application, deactivating a user means removing access to login, but maintaining the user's ssh access information as an inactive user.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Reactivate Users`}
        <ul parentName="li">
          <li parentName="ul">{`User accounts can be reactivated in the application.`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Overview`}</h2>
    <p>{`In this quickstart, we will:`}</p>
    <ol>
      <li parentName="ol">{`Create a new OIDC application in Okta, for single sign-on`}</li>
      <li parentName="ol">{`Enter OIDC details into the Smallstep SSH UI`}</li>
      <li parentName="ol">{`Add and configure the Smallstep application from the Okta Application Directory, for user sync via SCIM`}</li>
      <li parentName="ol">{`Check logs to confirm users and groups are syncing to Smallstep SSH`}</li>
    </ol>
    <h2>{`Step By Step Instructions`}</h2>
    <h3><strong parentName="h3">{`Step 1. Create Okta OIDC Application`}</strong></h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Start at your Okta admin dashboard (access via "Admin" button next to "+ Add Apps" after successful log in)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go to Applications → Create App Integration`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the pop up select "OIDC - OpenID Connect" as the sign-in method and specify "Native Application" for the Application type.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/ssh-okta-new-oidc-app.png",
            "alt": null,
            "title": "Okta OIDC"
          }}></img></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`New Native App Integration Page`}</p>
        <ul parentName="li">
          <li parentName="ul">{`App integration name: `}<inlineCode parentName="li">{`smallstep-oidc`}</inlineCode></li>
          <li parentName="ul">{`Sign-in redirect URIs: `}<inlineCode parentName="li">{`http://127.0.0.1:10000`}</inlineCode></li>
          <li parentName="ul">{`Select "Skip group assignment for now"`}</li>
          <li parentName="ul">{`All other value leave as default`}</li>
          <li parentName="ul">{`click save`}</li>
        </ul>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/ssh-okta-uri-oidc-app.png",
            "alt": null,
            "title": "Create OpenID"
          }}></img></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go to the `}<strong parentName="p">{`General`}</strong>{` tab → Scroll down to "Client Credentials" and choose "Edit"`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Select "Use Client Authentication" radio button`}</li>
          <li parentName="ul">{`Save`}</li>
        </ul>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/ssh-okta-client-auth-oidc-app.png",
            "alt": null
          }}></img></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go to the `}<strong parentName="p">{`Assignments`}</strong>{` tab.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Assign any groups that will need either `}<inlineCode parentName="li">{`sudo`}</inlineCode>{` and/or `}<inlineCode parentName="li">{`ssh`}</inlineCode>{` access to hosts to the `}<inlineCode parentName="li">{`smallstep-oidc`}</inlineCode>{` app:`}
            <ul parentName="li">
              <li parentName="ul">{`Assignments → Assign → Assign to Group → Click "Assign" for `}<inlineCode parentName="li">{`ssh`}</inlineCode></li>
              <li parentName="ul">{`Assignments → Assign → Assign to Group → Click "Assign" for `}<inlineCode parentName="li">{`sudo`}</inlineCode></li>
              <li parentName="ul">{`Repeat this process for any other groups you created for controlling SSH/sudo access`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go back to `}<strong parentName="p">{`General`}</strong>{` tab and scroll down to "Client Credentials." You'll refer to these values in the next step.`}</p>
      </li>
    </ol>
    <h3>{`Step 2. Enter your OIDC Details into the Smallstep dashboard`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Open a new browser tab and log in to Smallstep: `}<inlineCode parentName="p">{`https://smallstep.com/app/[TEAM-NAME]`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Navigate the Onboarding Dialog. If the dialog is not open, you can relaunch it by visiting the `}<strong parentName="p">{`Users`}</strong>{` tab.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/oidc-copy-2021.png",
            "alt": null,
            "title": "onboarding"
          }}></img></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Copy and paste your `}<strong parentName="p">{`Client ID`}</strong>{` and `}<strong parentName="p">{`Client Secret`}</strong>{` from Okta.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/okta-copy-oidcinfo.png",
            "alt": null,
            "title": "Client secret"
          }}></img></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The `}<strong parentName="p">{`configuration endpoint`}</strong>{` is derived from your Okta domain. Fill your Okta domain into the following URL:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`https://{your Okta domain}/.well-known/openid-configuration
`}</code></pre>
        <p parentName="li">{`This is your `}<strong parentName="p">{`Configuration Endpoint`}</strong>{`. For example, if you normally sign into Okta at `}<inlineCode parentName="p">{`https://example.okta.com/`}</inlineCode>{`, then your configuration endpoint is `}<inlineCode parentName="p">{`https://example.okta.com/.well-known/openid-configuration`}</inlineCode></p>
        <p parentName="li">{`You can copy your Okta domain from the OIDC application General tab under General Settings`}</p>
        <p parentName="li">{` `}<img parentName="p" {...{
            "src": "/graphics/quickstart/ssh-okta-domain-oidc-app.png",
            "alt": null,
            "title": "onboarding"
          }}></img></p>
      </li>
    </ol>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`"SAVE"`}</strong></li>
      <li parentName="ol">{`You've completed this portion of the setup.`}</li>
    </ol>
    <h3>{`Step 3. Add the Smallstep App Integration and Configure User Sync in Okta`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`In the OKTA admin console, add the Smallstep application`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Applications → Browse App Catalog`}</p>
            <ul parentName="li">
              <li parentName="ul">
                <p parentName="li">{`Search for `}<inlineCode parentName="p">{`Smallstep`}</inlineCode></p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Select the Smallstep app:`}</p>
                <p parentName="li"><img parentName="p" {...{
                    "src": "/graphics/quickstart/okta-app-tile.png",
                    "alt": null,
                    "title": "Smallstep Tile"
                  }}></img></p>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Click "Add"`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/okta-app-add.png",
            "alt": null
          }}></img></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Select "Do not display application icon to users"`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Select "Do not display application icon in the Okta Mobile App"`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`De-select "Automatically log in when user lands on login page"`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Click Next`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/okta-app-signon.png",
            "alt": null
          }}></img></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Select "Administrator sets username, user sets password"`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Application username format: "Okta username prefix"`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Update application username on "Create and update"`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Done`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Provisioning`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`Select the "Provisioning" tab`}</li>
          <li parentName="ul">{`Click "Configure API Integration" and select the checkbox next to "`}<strong parentName="li">{`Enable API integration`}</strong>{`"`}</li>
        </ul>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/okta-enable-api.png",
            "alt": null,
            "title": "Enable API"
          }}></img></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Return to the Smallstep dashboard, or open a new browser tab and sign into the Smallstep dashboard: `}<inlineCode parentName="p">{`https://smallstep.com/app/[TEAM-NAME]`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Navigate to the Onboarding UI → Add Your Team → SCIM Details`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Copy `}<strong parentName="p">{`Base URL,`}</strong>{` and `}<strong parentName="p">{`API Token`}</strong>{` from Smallstep dashboard, and paste into Okta Provisioning form.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In Okta, choose `}<strong parentName="p">{`Test API Credentials`}</strong>{`. After successful verification, choose `}<strong parentName="p">{`Save`}</strong>{`.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/okta-api-auth.png",
            "alt": null,
            "title": "API Auth"
          }}></img></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Reload the provision tab`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Under Provisioning → Settings → To App, choose `}<strong parentName="p">{`Edit`}</strong>{` and enable`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Create Users`}</li>
          <li parentName="ul">{`Update User Attributes`}</li>
          <li parentName="ul">{`Deactivate Users`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Save.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/okta-enable-sync.png",
            "alt": null,
            "title": "enable and save"
          }}></img></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Assignments`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Select the `}<strong parentName="li">{`Assignments`}</strong>{` tab → Click `}<strong parentName="li">{`Assign`}</strong>{`  → `}<strong parentName="li">{`Assign to Groups`}</strong></li>
          <li parentName="ul">{`Search by group → Assign any groups that will need either `}<inlineCode parentName="li">{`sudo`}</inlineCode>{` and/or `}<inlineCode parentName="li">{`ssh`}</inlineCode>{` access to hosts`}</li>
          <li parentName="ul">{`Group names that contain a `}<inlineCode parentName="li">{`/`}</inlineCode>{` are not supported in this release.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Push Groups`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Select the `}<strong parentName="li">{`Push Groups`}</strong>{` tab → `}<strong parentName="li">{`Push Groups`}</strong>{` → `}<strong parentName="li">{`Find Groups By Name`}</strong></li>
          <li parentName="ul">{`Search for the same groups that will need either `}<inlineCode parentName="li">{`sudo`}</inlineCode>{` and/or `}<inlineCode parentName="li">{`ssh`}</inlineCode>{` access to hosts.`}</li>
          <li parentName="ul">{`Save.`}</li>
          <li parentName="ul">{`Repeat for each desired group.`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`Step 4. Sign in to the smallstep UI`}</h3>
    <p>{`Sign in at `}<inlineCode parentName="p">{`https://smallstep.com/app/[Team ID]`}</inlineCode></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Select the `}<strong parentName="p">{`Logs`}</strong>{` tab. You should see a list of success messages assocated with `}<inlineCode parentName="p">{`SCIM-SYNC`}</inlineCode>{` catagory items.`}</p>
        <p parentName="li">{` `}<img parentName="p" {...{
            "src": "/graphics/quickstart/scim-logs.png",
            "alt": null,
            "title": "SCIM Logs"
          }}></img></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Select the `}<strong parentName="p">{`Users`}</strong>{` tab. If the onboarding dialog is open, press `}<inlineCode parentName="p">{`Esc`}</inlineCode>{` to close.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You should see your Users and Groups synced over from Okta.`}</p>
      </li>
    </ul>
    <h3>{`OKTA Configuration Complete`}</h3>
    <h2>{`Troubleshooting Tips`}</h2>
    <ul>
      <li parentName="ul">{`Initial activation of Okta OIDC provisioning in Smallstep SSH requires entering your `}<strong parentName="li">{`Client ID, Client Secret`}</strong>{`, and `}<strong parentName="li">{`base domain of your Okta instance.`}</strong>{` Contact smallstep support with any questions | `}<a parentName="li" {...{
          "href": "mailto:support@smallstep.com"
        }}>{`support@smallstep.com`}</a></li>
      <li parentName="ul">{`UIDs and GIDs. By default, Smallstep will auto-assign POSIX UIDs and GIDs for your users. Alternatively, you can sync POSIX UIDs and GIDs values from your Okta directory to Smallstep.`}
        <ul parentName="li">
          <li parentName="ul">{`See our guide `}<a parentName="li" {...{
              "href": "https:/prof.infra.smallstep.com/docs/ssh/okta-gid-uid"
            }}>{`How to sync UIDs and GIDs from Okta`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`If you are having trouble synchronizing users or groups, it's always recommended to resynchronize the information manually:`}
        <ul parentName="li">
          <li parentName="ul">{`Applications  → Smallstep → Push Groups → Find Group → "Push Now'`}</li>
        </ul>
      </li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/graphics/quickstart/okta-push-groups.png",
        "alt": null
      }}></img></p>
    <ul>
      <li parentName="ul">{`When users are deactivated in Okta, they will be deactivated in Smallstep. Users will not be able to SSH to servers, but their user accounts will remain on smallstep managed hosts. To permanently delete user data on smallstep managed hosts, contact Smallstep Support | `}<a parentName="li" {...{
          "href": "mailto:support@smallstep.com"
        }}>{`support@smallstep.com`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      